import useVuexModule from '@shared/composables/vuexModule.js'
import { defineStore } from 'pinia'

// TODO: Partial implementation (ENG-2843)
export const useAccountStore = defineStore('account', () => {
  const vuex = useVuexModule('account', {
    defaults: {
      state: [
        ['pickupConfirmationCode', null],
        ['pickupLocations', []],
        ['localPickupLocations', []]
      ]
    }
  })

  return {
    ...vuex
  }
})
