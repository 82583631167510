import api from '../api/api'
import { getSetters, parseError } from './helpers/shared'

export const state = {
  pickupConfirmationCode: null,
  updatingAddress: false,
  shippingAddresses: [],
  pickupLocations: [],
  pickupLocationsLoading: true,
  breadcrumbs: [],
  itemPromotions: null,
  giftCardTransactions: [],
  showPlanUpdateSuccess: false,
  confirmChangeTitle: 'Confirm Plan Change',
  returnLabelLink: 'https://fulfillment.fedex.com/web/commerce/rma-web?customerId=2554700&token=eyJjdHkiOiJKV1QiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiZGlyIn0..adkcD8qoXfVYbtVp_KThEQ.sxkvOX7c6EYqM9x4c4f96WTy26POCOFUcEgOoaQm-RUWTduiAsYv82sKw2oaO8zt6HpQzjtrexfYDrJyCuN9vDi6r8N3Vza4g0m-SS7l9349x4XU_kmloqtxwHRrnv5XYkEdSL1cQLj3np0JUSOCGun0QuZOTWv3Joz8V-IxSn9kKsoaIb2hJTo8zt1Du8Dgvau2cBnoDrcvOTldwuN_oc59TyyKUdb10cT14jalnFL38gJaL7uR6S5b4Do1yRwUS0yF-PxlaMQnu0NboCLK-ScWXfPkk4Lw4bleq8H5P_oOH_UaQrU7gYkhOt87xljnRkA1JHPckrRsnOVMGI7L5XzmSYhzW07c33A25HCsBQ259XPir4XQ1gAQQpo72Ixo.tBZzIUfY4FiFhjMhDo31lg'
}

export const getters = {
  localPickupLocations: (state) => {
    return state.pickupLocations.filter(x => x.isLocalToMember)
  },
  pickupLocationKiosks: (state) => {
    return state.pickupLocations.reduce((kiosks, currLocation) => {
      if (currLocation.kiosks) return kiosks.concat(currLocation.kiosks.map(kiosk => ({ ...kiosk, location: currLocation })))
      return kiosks
    }, [])
  }
}

export const actions = {
  async submitPickupRequest ({ commit }, data) {
    try {
      const res = await api.apiClient.submitPickupRequest(data)
      commit('SET_PICKUP_CONFIRMATION_CODE', res.data.confirmationCode)
    } catch (err) {
      throw parseError(err)
    }
  },
  async getPickupConfirmationCode ({ commit }) {
    try {
      const res = await api.apiClient.getFedExPickupConfirmationCode()
      commit('SET_PICKUP_CONFIRMATION_CODE', res.data.confirmationCode)
      return res.data.confirmationCode
    } catch (err) {
      throw parseError(err)
    }
  },
  async deleteShippingAddress ({ commit, state }, data) {
    try {
      commit('SET_UPDATING_ADDRESS', true)
      await api.apiAccount.deleteAddress(data.id)
      commit('SET_SHIPPING_ADDRESSES', state.shippingAddresses.filter((address) => {
        return address.id !== data.id
      }
      ))
    } catch (err) {
      throw parseError(err)
    } finally {
      commit('SET_UPDATING_ADDRESS', false)
    }
  },
  async selectReturnCarrierPreference ({ commit }, pref) {
    try {
      await api.apiClient.updateClient({
        return_carrier_preference: pref
      })
      commit(
        'client/UPDATE_CLIENT',
        { returnCarrierPreference: pref, showFedexSuccessModal: pref === 'FEDEX' },
        { root: true })
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateApplyGiftCardsToSubscriptionChoice ({ commit }, pref) {
    commit('client/UPDATE_APPLY_GIFT_CARDS_TO_SUBSCRIPTIONS', { pref }, { root: true })
    try {
      await api.apiClient.updateClient({
        apply_gift_cards_to_subscriptions: pref
      })
    } catch (err) {
      throw parseError(err)
    }
  },
  async updateReplacementItemChoice ({ commit }, pref) {
    commit('client/UPDATE_REPLACEMENT_CHOICE', { pref }, { root: true })
    try {
      await api.apiClient.updateClient({
        replacement_item_choice: pref
      })
    } catch (err) {
      throw parseError(err)
    }
  },
  async selectShippingAddress ({ commit, rootState }, data) {
    const beforeData = {
      validatedShippingAddress: rootState.client.validatedShippingAddress,
      shippingAddress: rootState.client.shippingAddress,
      pickupAddress: rootState.client.pickupAddress
    }
    try {
      commit('SET_UPDATING_ADDRESS', true)
      commit(
        'client/UPDATE_CLIENT',
        {
          validatedShippingAddress: {
            valid: true,
            originalAddress: data
          },
          shippingAddress: data,
          pickupAddress: null
        },
        { root: true })
      await api.apiClient.updateClient({
        shipping_address: data.id
      })
    } catch (err) {
      commit(
        'client/UPDATE_CLIENT',
        {
          validatedShippingAddress: beforeData.validatedShippingAddress,
          shippingAddress: beforeData.shippingAddress,
          pickupAddress: beforeData.pickupAddress
        },
        { root: true })
      throw parseError(err)
    } finally {
      commit('SET_UPDATING_ADDRESS', false)
    }
  },
  async selectPickupLocation ({ commit, rootState }, data) {
    const beforeData = {
      pickupAddress: rootState.client.pickupAddress,
      shippingAddress: rootState.client.shippingAddress
    }
    try {
      commit('SET_UPDATING_ADDRESS', true)
      commit(
        'client/UPDATE_CLIENT',
        {
          pickupAddress: data,
          shippingAddress: null
        },
        { root: true })
      await api.apiClient.updateClient({
        pickup_location: data.id
      })
    } catch (err) {
      commit(
        'client/UPDATE_CLIENT',
        {
          pickupAddress: beforeData.pickupAddress,
          shippingAddress: beforeData.shippingAddress
        },
        { root: true })
      throw parseError(err)
    } finally {
      commit('SET_UPDATING_ADDRESS', false)
    }
  },
  async getPickupLocations ({ commit }) {
    commit('SET_PICKUP_LOCATIONS_LOADING', true)
    const res = await api.apiAccount.getPickupLocations()
    commit('SET_PICKUP_LOCATIONS', res.data)
    commit('SET_PICKUP_LOCATIONS_LOADING', false)
  },
  async getShippingAddresses ({ commit }, username) {
    const res = await api.apiAccount.getShippingAddresses(username)
    commit('SET_SHIPPING_ADDRESSES', res.data)
  },
  async addAddress ({ commit }, data) {
    try {
      commit('SET_UPDATING_ADDRESS', true)
      const res = await api.apiAccount.addAddress(data)
      const addresses = state.shippingAddresses.slice(0)
      addresses.unshift(res.data.originalAddress)
      commit('SET_SHIPPING_ADDRESSES', addresses)
      commit('client/UPDATE_CLIENT', { mainPhone: res.data.mainPhone }, { root: true })
      return res.data.originalAddress
    } catch (err) {
      if (err.response.data) { throw err.response.data }
      throw parseError(err)
    } finally {
      commit('SET_UPDATING_ADDRESS', false)
    }
  },
  async updateAddress ({ commit, state }, data) {
    try {
      commit('SET_UPDATING_ADDRESS', true)
      const res = await api.apiAccount.updateAddress(data)
      if (res.data.valid) {
        const addresses = state.shippingAddresses.slice(0)
        const i = addresses.findIndex(address => address.id === res.data.originalAddress.id)
        addresses[i] = res.data.originalAddress
        commit('SET_SHIPPING_ADDRESSES', addresses)
        commit('client/UPDATE_CLIENT', { mainPhone: res.data.mainPhone }, { root: true })
      }
      return res.data
    } catch (err) {
      if (err.response && err.response.data) { throw err.response.data }
      throw parseError(err)
    } finally {
      commit('SET_UPDATING_ADDRESS', false)
    }
  },
  async updateClient ({ commit }, data) {
    try {
      await api.apiClient.updateClient(data)
      commit(
        'client/UPDATE_CLIENT',
        data,
        { root: true })
    } catch (err) {
      throw parseError(err)
    }
  },
  async updatePassword (context, password) {
    try {
      await api.apiClient.updateClient({ password: password })
    } catch (err) {
      throw parseError(err)
    }
  },
  async validatePassword (context, password) {
    try {
      const res = await api.apiAccount.validatePassword(
        { password: password })
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async activateGiftCard ({ dispatch }, code) {
    try {
      await api.apiAccount.activateGiftCard(code)
      dispatch('getGiftCardTransactions')
    } catch (err) {
      throw parseError(err)
    }
  },
  async getGiftCardTransactions ({ commit }) {
    try {
      const res = await api.apiAccount.getGiftCardTransactions()
      commit('SET_GIFT_CARD_TRANSACTIONS', res.data)
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async getItemPromotions ({ commit }) {
    const res = await api.apiAccount.getItemPromotions()
    const available = []; const redeemed = []; const expired = []
    for (let i = 0; i < res.data.length; i++) {
      const promo = res.data[i]
      promo.expiration = promo.expiration ? new Date(promo.expiration).toISOString() : null
      if (promo.redeemed != null) {
        redeemed.push(promo)
      } else if (promo.expiration && new Date(promo.expiration) < new Date().setHours(0, 0, 0, 0)) {
        expired.push(promo)
      } else {
        available.push(promo)
      }
    }
    // Sort the different categories.  available - expires earliest to latest.  redeemed and expired, laest to earliest.
    commit('SET_ITEM_PROMOTIONS', {
      available: available.sort((a, b) => new Date(a.expiration) - new Date(b.expiration)),
      redeemed: redeemed.sort((a, b) => new Date(b.redeemed) - new Date(a.redeemed)),
      expired: expired.sort((a, b) => new Date(b.expiration) - new Date(a.expiration))
    })
  },
  async cancelSubscription (context, cancelReason) {
    try {
      return await api.apiClient.cancelSubscription(cancelReason)
    } catch (err) {
      throw parseError(err)
    }
  },
  async pauseSubscription ({ commit }, { months }) {
    try {
      const res = await api.apiClient.pauseSubscription(months)
      commit(
        'client/UPDATE_CLIENT',
        res.data.client,
        { root: true })
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async unpauseSubscription ({ commit }) {
    try {
      const res = await api.apiClient.unpauseSubscription()
      commit(
        'client/UPDATE_CLIENT',
        res.data.client,
        { root: true })
      return res
    } catch (err) {
      throw parseError(err)
    }
  },
  async kioskCheckin (_, kioskId) {
    try {
      await api.apiAccount.kioskCheckin(kioskId)
    } catch (err) {
      throw parseError(err)
    }
  },
  async deleteAccount (_, username) {
    try {
      await api.apiAccount.deleteClient(username)
    } catch (err) {
      throw parseError(err)
    }
  }
}

export const mutations = {
  ...getSetters(state)
}
