import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useClientStore } from '@shared/stores/client.js'
import { useAccountStore } from '@/stores/account.js'

export const useReturnCarrier = () => {
  const { returnCarrierPreference, shippingAddress, pickupAddress } = storeToRefs(useClientStore())
  const { pickupLocations, localPickupLocations } = storeToRefs(useAccountStore())

  const fedexPreferred = computed(() => returnCarrierPreference.value === 'FEDEX')
  const upsPreferred = computed(() => returnCarrierPreference.value === 'UPS')
  const uspsPreferred = computed(() => returnCarrierPreference.value === 'USPS')
  const printReturnLabelLink = computed(() => {
    return shippingAddress.value?.printReturnLabelLink ??
    pickupAddress.value?.printReturnLabelLink ??
    ''
  })
  const isMoovnEligible = computed(() => localPickupLocations.value.length > 0 && pickupLocations.value[0]?.city === 'Seattle')
  return {
    fedexPreferred,
    upsPreferred,
    uspsPreferred,
    printReturnLabelLink,
    isMoovnEligible
  }
}
