<template>
  <div
    v-for="(pickupLocation, index) in pickupLocations"
    :key="index">
    <div
      class="mt-3">
      <h5>
        {{ pickupLocation.city }}
      </h5>
      <p>
        We offer Return drop offs and Case pickups at our boutique, <strong>{{ pickupLocation.name }}</strong>, located at {{ pickupLocation.addressLine1 }}.
      </p>
      <div
        v-if="pickupLocation.timings">
        <div>
          It is open:
          <ul
            class="mt-1">
            <li
              v-for="(timing, key) in pickupLocation.timings"
              :key="key">
              {{ timing }}
            </li>
          </ul>
        </div>
      </div>
      <p
        v-if="pickupLocation.city === 'Seattle'">
        If you choose to have your Case delivered with our Seattle-area same day
        shipping partner, <strong>Moovn</strong>, you can schedule a free return pickup.
        Moovn will deliver your new Case and pick up your returning items
        in the same trip.
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const pickupLocations = computed(() => store.state.account.pickupLocations)

</script>
